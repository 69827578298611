import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { of } from 'rxjs';
import { Audience, SaveSettingsRequest, Settings } from './models';

@Injectable({ providedIn: 'root' })
export class PharmzillaCommunicationService {
  private readonly http = inject(HttpClient);

  private readonly tenantCode = inject(INSTANCE_CODE);

  // TODO: после мержа ветки с ЦА убрать этот метод остюда и поменять на метод из сервиса ЦА
  getTargetAudience(search: string) {
    return this.http.get<Audience[]>(
      `${this.tenantCode}/api/pharmzilla/v1/audiences?limit=50&offset=0`,
      { params: search ? { name: search } : {} }
    );
  }

  getTargetAudienceByIds(ids: number[]) {
    if (ids.length <= 0) {
      return of([]);
    }
    return this.http.get<Audience[]>(
      `${this.tenantCode}/api/pharmzilla/v1/audiences`,
      { params: { id: ids } }
    );
  }

  getSettings(id: number) {
    return this.http.get<Settings>(
      `${this.tenantCode}/api/pharmzilla/v1/communications/templates/${id}/settings`
    );
  }

  saveSettings(id: number, request: SaveSettingsRequest) {
    return this.http.put(
      `${this.tenantCode}/api/pharmzilla/v1/communications/templates/${id}/settings`,
      request
    );
  }

  saveDefaultSettings(communicationId: number) {
    return this.http.put(
      `${this.tenantCode}/api/pharmzilla/v1/communications/templates/${communicationId}/settings`,
      {
        audiences: [],
        isDirectTemplate: false,
        isForMobile: false,
        isVideoCallTemplate: false,
        validFrom: null,
        validTo: null
      } as SaveSettingsRequest
    );
  }
}
